@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { font-family: "IconFont"; src: url("https://assets.codepen.io/6932556/IconFont-Regular-0.9.3.woff2") format("woff2"); }

:root {
  --primary: #FFFFFF;
  --secondary: #000000;
  --detail: #FFFFFF;
  --detail2: #C7D6F1;
}

.dark {
  --primary: #000000;
  --secondary: #FFFFFF;
  --detail: #FFFFFF;
  --detail2: #C7D6F1;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading {
  font-family: monospace;
}

.container {
  height: 10em;
  position: relative
}

.cube {
  text-align: center;
  padding: 20px;
  margin: 50px;
  position: center;
  /*word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word; */
  color:#ccc; 
  font-size: 10pt;
}


.heading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
  padding: 40px 40px;
  z-index: 5;
}


.themeicon {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}



ul svg path {
  fill: var(--secondary);
  stroke: var(--secondary);
}

svg circle {
  fill: var(--secondary);
  stroke: var(--secondary);
}

svg rect {
  fill: var(--secondary);
  stroke: var(--secondary);
}

svg polygon {
  fill: var(--secondary);
  stroke: var(--secondary);
}

.my-music-icon {
  fill: none !important;
}


.heading .logo {
  width: var(--big-logo, 100px);
  float: left;
  position: absolute;
  left: 15px;
  transition: 0.6s;
  cursor: pointer;
}

.heading .logo:active {
  filter: saturate(0);
  transition: none !important;
}





.heading .themeicon {
  width: var(--big-run, 32px);
  fill: var(--secondary);
  float: right;
  position: absolute;
  right: var(--run-pos, 20px);
  transition: 0.6s;
  cursor: pointer;
  top: 5vh;
}

.heading ul {
  position: center;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.heading ul li {
  position: relative;
  list-style: none;
}

.heading ul li a {
  position: relative;
  margin: var(--nav-spc, 55px);
  text-decoration: none;
  color: var(--secondary);
  transition: 0.6s;
  font-family: monospace;
}

.heading ul li a:active {
  color: var(--detail2);
  transition: none !important;
}


.d {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body {
  background: var(--primary);
  transition: 0.6s;
}

/* contact dialogbox */

.dialogbox {
  transition: 0.6s;
  position: absolute;
  top: 0px;
  right: 0px;
  line-break: 0px;
  min-width: var(--db-width, 100px);
  height: 110px;
  
}

.dialogbox .body {
  position: absolute;
  min-width: var(--db-width, 100px);
  background-color: var(--secondary);
}

.body .message {
  border-radius: 3px;
  font-family: monospace;
  text-transform: lowercase;
  font-size: 12px;
  color: var(--detail);
  text-align: right;
}

.message a {
  color: black;
  text-decoration: none;
}



.dropline {
  padding: 3.5px;
}

.dropline:hover,
.dropline a:hover {
  background: var(--primary);
  color: var(--detail2);
  transition: 0.2s;
  cursor: pointer;
}



/* Screen Resizing */

@media (max-width: 650px) {
  .heading .logo {
    --big-logo: calc(100px / 2);
    --logo-pos: 20px;
  }
  .heading .themeicon {
    --big-run: calc(32px * 0.75);
    --run-pos: 20px;
  }
  .heading ul li a {
    --nav-spc: 20px;
  }
.container{
  --photos: 50px 50px;
  }
}



@media (max-width: 350px) {
  .heading .logo {
    left: 10px;
  }
  .heading .themeicon {
    right: 10px;
  }
  .heading ul li a {
    --nav-spc: 5px;
  }

}

.threedee {
  padding-top: 200px;
}


.d {
user-drag: none;
-webkit-user-drag: none;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
}
.player-controls {
display: flex;
align-items: center;
}

.player-button {
height: 1rem;
width: 1rem;
position: relative;
transition: opacity .4s ease,transform .4s ease;
}


.player-button:active {
opacity: 50%;
transform: scale(0.25);
}

.musical-note {
  margin-block-start: 4.3em;
}

.player__button__play {
  transition: opacity .4s ease,transform .4s ease;
  width: 1rem;
  position: absolute;
  height: 1rem;
}

.player__button__pause {
  transition: opacity .4s ease,transform .4s ease;
  width: 1rem;
  position: absolute;
  height: 1rem;
}

.player__button__play:active {
  opacity: 50%;
  transform: scale(0.25);
}

.player__button__pause:active {
  opacity: 50%;
  transform: scale(0.25);
}

/* SoundCloud Widget Music */
/* SC Widget */

iframe.sc-widget {
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.player-container {
  border: 2px solid var(--secondary);
}

.player {
  display: flex;
  flex-wrap: wrap;

}

.player-info {
  padding: 20px;
  width: 50%;
  background: var(--primary);
  text-wrap: none;
  white-space: nowrap;
  height: 150px;
}

.player-time {
  display: flex;
  font-size: 13px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.current,
.duration {
  margin: 0px 30px 0px 0px;
  color: var(--secondary);
}


.sidenav {
  font-family: 'Space Mono';
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  height: 190px;
  width: 300px;
  position: fixed;
  z-index: 1;
  bottom: var(--musictab, 50px);
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 20;
}

.sidenav a {
  width: inherit;
  text-decoration: none;
  color: var(--secondary);
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: var(--detail);
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav a {
    font-size: 18px;
  }
}

#playermenu {
  cursor: grab;
  position: fixed;
  width: 30px;
  height: 154px;
  right: 300px;
  float: right;
  transition: 0.5s;
  background: var(--primary);
  border-radius: 25px 0 0 25px;

  border: 2px solid var(--secondary);
  
  border-right: none;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1 !important;
}

.drag-header {
  position: absolute;
  left: 4.5px;
  top: 0px;
  bottom: 0;
  z-index: 20;
  width: inherit;
  height: inherit;
  color: var(--dark-1);
}

.play {
  height: 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid green;
}

#playermenu svg {
  display: block;
  margin-block-start: 4.3em;
  width: 18px;
  height: 18px;
}

  
.player-title-outer {
  width: 200px; /* set the width of the viewport */
  overflow: hidden; /* hide the overflowing content */
  padding-bottom: 5px;
}

.player-title {
  color: var(--primary);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 12px;
}

.player-title-marquee {
  animation: marquee 20s linear infinite;
}



.player-visual {
  display: flex;
}

.record-box {
  display: flex;
  right: 0;
  position: absolute;
  top: 0;
  padding: 30px;
  background: var(--primary);
  border: 2px solid var(--secondary);
  border-left: 2px solid var(--primary);
  max-height: 154px;

}

.record {
  object-fit: fill;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.rotate {
  animation: rotation 8s infinite linear;
}

.paused {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0); /* start from the original position */
  }
  75% {
    transform: translateX(-100%); /* move to the left by the width of the text */
  }
  100% {
    transform: translateX(0%); /* move to the left by the width of the text */
  }
}


::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

code {
  font-family: 'Space Mono';
}

.loading {
  font-family: 'Space Mono';
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35em;
}

.cube {
text-align: center;
padding: 20px;
margin: 50px;
position: center;
font-family: 'Space Mono';
/*word-wrap: break-word;
white-space: pre-wrap;
word-break: break-word; */
color: var(--dark-4); 
font-size: 10pt;
}


@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;

}

@layer base {
  * {
    @apply box-border list-none p-0 m-0 scroll-smooth;
  }

  :root {
    --primary-500: #B4926Aff;
    --primary-600: #E2CAAFff;
    --secondary-500: #87835Bff;
    --off-white: #939FA7ff;
    --red: #EB5351ff;
    --dark-1: #000000;
    --dark-2: #09090A;
    --dark-3: #101012;
    --dark-4: #1F1F22;
    --primary: #FFFFFF;
    --light-2: #EFEFEF;
    --light-3: #DBDBDB;
    --light-4: #5C5C7B;
  }
  .dark {
    --primary-500: #B4926Aff;
    --primary-600: #E2CAAFff;
    --secondary-500: #87835Bff;
    --off-white: #939FA7ff;
    --red: #EB5351ff;
    --dark-1: #FFFFFF;
    --dark-2: #EFEFEF;
    --dark-3: #DBDBDB;
    --dark-4: #5C5C7B;
    --primary: #000000;
    --light-2: #09090A;
    --light-3: #DBDBDB;
    --light-4: #bababa;
  }

  
}



@layer utilities {
  /* TYPOGRAPHY */
  .h1-bold {
    @apply text-[36px] font-bold leading-[140%] tracking-tighter;
  }

  .h1-semibold {
    @apply text-[36px] font-semibold leading-[140%] tracking-tighter;
  }

  .h2-bold {
    @apply text-[30px] font-bold leading-[140%] tracking-tighter;
  }

  .h3-bold {
    @apply text-[24px] font-bold leading-[140%] tracking-tighter;
  }

  .base-semibold {
    @apply text-[16px] font-semibold leading-[140%] tracking-tighter;
  }

  .base-medium {
    @apply text-[16px] font-medium leading-[140%];
  }

  .base-regular {
    @apply text-[16px] font-normal leading-[140%];
  }

  .body-bold {
    @apply text-[18px] font-bold leading-[140%];
  }

  .body-medium {
    @apply text-[18px] font-medium leading-[140%];
  }

  .small-semibold {
    @apply text-[14px] font-semibold leading-[140%] tracking-tighter;
  }

  .small-medium {
    @apply text-[14px] font-medium leading-[140%];
  }

  .small-regular {
    @apply text-[14px] font-normal leading-[140%];
  }

  .subtle-semibold {
    @apply text-[12px] font-semibold leading-[140%];
  }

  .tiny-medium {
    @apply text-[10px] font-medium leading-[140%];
  }

  /* UTILITIES */
  .invert-white {
    @apply invert brightness-0 transition;
  }

  .flex-center {
    @apply flex justify-center items-center;
  }

  .flex-between {
    @apply flex justify-between items-center;
  }

  .flex-start {
    @apply flex justify-start items-center;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 2px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #09090a;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #cfcfd1;
    border-radius: 50px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #7878a3;
  }

  .common-container {
    @apply flex flex-col flex-1 items-center gap-10 overflow-scroll py-10 px-5 md:px-8 lg:p-14 custom-scrollbar;
  }

  /* All Users */
  .user-container {
    @apply max-w-5xl flex flex-col items-start w-full gap-6 md:gap-9;
  }

  .user-grid {
    @apply w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-7 max-w-5xl;
  }

  /* Explore */
  .explore-container {
    @apply flex flex-col flex-1 items-center overflow-scroll py-10 px-5 md:p-14 custom-scrollbar;
  }

  .explore-inner_container {
    @apply max-w-5xl flex flex-col items-center w-full gap-6 md:gap-9;
  }

  .explore-search {
    @apply h-12 bg-dark-4 border-none placeholder:text-light-4 focus-visible:ring-0 focus-visible:ring-offset-0 ring-offset-0 !important;
  }

  /* Home */
  .home-container {
    @apply flex flex-col flex-1 items-center gap-10 overflow-scroll py-10 px-5 md:px-8 lg:p-14 custom-scrollbar;
  }

  .home-posts {
    @apply max-w-screen-sm flex flex-col items-center w-full gap-6 md:gap-9;
  }

  .home-creators {
    @apply hidden xl:flex flex-col w-72 2xl:w-465 px-6 py-10 gap-10  overflow-scroll custom-scrollbar;
  }

  /* Post Details */
  .post_details-container {
    @apply flex flex-col flex-1 gap-10 overflow-scroll py-10 px-5 md:p-14 custom-scrollbar items-center;
  }

  .post_details-card {
    @apply bg-dark-2 w-full max-w-5xl rounded-[30px] flex-col flex xl:flex-row border border-dark-4 xl:rounded-l-[24px];
  }

  .post_details-img {
    @apply h-80 lg:h-[480px] xl:w-[48%] rounded-t-[30px] xl:rounded-l-[24px] xl:rounded-tr-none object-cover p-5 bg-dark-1;
  }

  .post_details-info {
    @apply bg-dark-2 flex flex-col gap-5 lg:gap-7 flex-1 items-start p-8 rounded-[30px];
  }

  .post_details-delete_btn {
    @apply p-0 flex gap-3 hover:bg-transparent hover:text-light-1  text-light-1 small-medium lg:base-medium;
  }

  /* Profile */
  .profile-container {
    @apply flex flex-col items-center flex-1 gap-10 overflow-scroll py-10 px-5 md:p-14 custom-scrollbar;
  }

  .profile-inner_container {
    @apply flex items-center md:mb-8 xl:items-start gap-8 flex-col xl:flex-row relative max-w-5xl w-full;
  }

  .profile-tab {
    @apply flex-center gap-3 py-4 w-48 bg-dark-2  transition flex-1 xl:flex-initial;
  }

  /* Saved */
  .saved-container {
    @apply flex flex-col flex-1 items-center gap-10 overflow-scroll py-10 px-5 md:p-14 custom-scrollbar;
  }

  /* Bottom bar */
  .bottom-bar {
    @apply z-50 flex items-center justify-center w-full sticky bottom-0 px-5 py-4 md:hidden;
  }

  /* File uploader */
  .file_uploader-img {
    @apply h-80 lg:h-[480px] w-full rounded-[24px] object-cover object-top;
  }

  .file_uploader-label {
    @apply text-light-4 text-center small-regular w-full p-4 border-t border-t-dark-4;
  }

  .file_uploader-box {
    @apply flex-center flex-col p-7 h-80 lg:h-[612px];
  }

  /* Grid Post List */
  .grid-container {
    @apply w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-7 max-w-5xl;
  }

  .grid-post_link {
    @apply flex rounded-[24px] border border-dark-4 overflow-hidden cursor-pointer w-full h-full;
  }

  .grid-post_user {
    @apply absolute bottom-0 p-5 flex-between w-full bg-gradient-to-t from-dark-3 to-transparent rounded-b-[24px] gap-2;
  }

  /* Left sidebar */
  .leftsidebar {
    @apply hidden md:flex px-6 py-10 flex-col justify-between min-w-[270px] bg-dark-2;
  }

  .leftsidebar-link {
    @apply rounded-lg base-medium hover:bg-primary-500 transition;
  }

  /* Post Card */
  .post-card {
    @apply bg-dark-2 rounded-3xl border border-dark-4 p-5 lg:p-7 w-full max-w-screen-sm;
  }

  .post-card_img {
    @apply h-64 xs:h-[400px] lg:h-[450px] w-full rounded-[24px] object-cover mb-5;
  }

  /* Topbar */
  .topbar {
    @apply sticky top-0 z-50 md:hidden bg-dark-2 w-full;
  }

  /* User card */
  .user-card {
    @apply flex-center flex-col gap-4 border border-dark-4 rounded-[20px] px-5 py-8;
  }
  
}

@layer components {
  /* SHADCN COMPONENTS */
  /* Form */
  .shad-form_label {
    @apply text-light-4 !important;
  }

  .shad-calendar {
    @apply	bg-light-1 !important;
  }

  .shad-form_message {
    @apply text-red !important;
  }

  .shad-input {
    @apply h-12 border-dark-1 bg-light-2 placeholder:text-light-4 focus-visible:ring-1 focus-visible:ring-offset-1 ring-offset-light-3 !important;
  }

  .shad-textarea {
    @apply h-36 bg-light-2 rounded-xl border-dark-1 focus-visible:ring-1 focus-visible:ring-offset-1 ring-offset-light-3 !important;
  }

  /* Button */
  .shad-button_primary {
    @apply h-12 bg-light-3 text-light-1 hover:bg-primary-500 hover:text-light-1 !important;
  }

  .shad-button_dark_4 {
    @apply h-12 bg-dark-4 px-5 text-light-1 flex gap-2 hover:text-light-1 hover:bg-primary-500 !important;
  }

  .shad-button_ghost {
    @apply flex gap-4 items-center justify-start hover:bg-transparent hover:text-light-1 !important;
  }
  .btn-radial-gray {
    @apply bg-radial-gray rounded-full w-1;
    /* Add additional styling for button shape, size, etc. */
  }
  .btn-radial-green {
    @apply bg-radial-green rounded-full w-1;
    /* Add additional styling for button shape, size, etc. */
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



.hide-scrollbar::-webkit-scrollber {
  display: none;
}

.d {
user-drag: none;
-webkit-user-drag: none;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
}
.player-controls {
display: flex;
align-items: center;
}

.player-button {
height: 1rem;
width: 1rem;
padding: 5px;
margin-right: 20px;
position: relative;
transition: opacity .4s ease,transform .4s ease;
}


.player-button:active {
opacity: 50%;
transform: scale(0.25);
}

.musical-note {
  margin-block-start: 4.3em;
}

.player__button__play {
  transition: opacity .4s ease,transform .4s ease;
  width: 1rem;
  position: absolute;
  height: 1rem;
}

.player__button__pause {
  transition: opacity .4s ease,transform .4s ease;
  width: 1rem;
  position: absolute;
  height: 1rem;
}

.player__button__play:active {
  opacity: 50%;
  transform: scale(0.25);
}

.player__button__pause:active {
  opacity: 50%;
  transform: scale(0.25);
}

/* SoundCloud Widget Music */
/* SC Widget */

iframe.sc-widget {
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.player-container {
  border: 2px solid var(--secondary);
}

.player {
  display: flex;
  flex-wrap: wrap;

}

.player-info {
  padding: 20px;
  width: 50%;
  background: var(--primary);
  text-wrap: none;
  white-space: nowrap;
  height: 150px;
}

.player-time {
  display: flex;
  font-size: 13px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.current,
.duration {
  margin: 0px 30px 0px 0px;
  color: var(--secondary);
}


.sidenav {
  font-family: 'Space Mono';
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  height: 190px;
  width: 300px;
  position: fixed;
  z-index: 1;
  bottom: var(--musictab, 50px);
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 20;
}

.sidenav a {
  width: inherit;
  text-decoration: none;
  color: var(--secondary);
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: var(--secondary);
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav a {
    font-size: 18px;
  }
}

#playermenu {
  cursor: grab;
  position: fixed;
  width: 30px;
  height: 154px;
  right: 300px;
  float: right;
  transition: 0.5s;
  background: var(--primary);
  border-radius: 25px 0 0 25px;

  border: 2px solid var(--secondary);
  
  border-right: none;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1 !important;
}

.drag-header {
  position: absolute;
  left: 4.5px;
  top: 0px;
  bottom: 0;
  z-index: 20;
  width: inherit;
  height: inherit;
  color: var(--secondary);
}

.play {
  height: 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid green;
}

#playermenu svg {
  display: block;
  margin-block-start: 4.3em;
  width: 18px;
  height: 18px;
}

  
.player-title-outer {
  width: 200px; /* set the width of the viewport */
  overflow: hidden; /* hide the overflowing content */
  padding-bottom: 5px;
}

.player-title {
  color: var(--secondary);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 12px;
}

.player-title-marquee {
  animation: marquee 20s linear infinite;
}



.player-visual {
  display: flex;
}

.record-box {
  display: flex;
  right: 0;
  position: absolute;
  top: 0;
  padding: 35px;
  background: var(--primary);
  border: 2px solid var(--secondary);
  border-left: 2px solid var(--primary);

}

.record {
  object-fit: fill;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.rotate {
  animation: rotation 8s infinite linear;
}

.paused {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0); /* start from the original position */
  }
  75% {
    transform: translateX(-100%); /* move to the left by the width of the text */
  }
  100% {
    transform: translateX(0%); /* move to the left by the width of the text */
  }
}


::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

code {
  font-family: 'Space Mono';
}

.loading {
  font-family: 'Space Mono';
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35em;
}

.cube {
text-align: center;
padding: 20px;
margin: 50px;
position: center;
font-family: 'Space Mono';
/*word-wrap: break-word;
white-space: pre-wrap;
word-break: break-word; */
color: var(--dark-4); 
font-size: 10pt;
}


.progress-bar-container {
  width: 100%;
  height: 4px;
  background-color: var(--secondary);
  cursor: pointer;
  margin: 10px 0;
  border-radius: 2px;
}

.progress-bar {
  height: 100%;
  background-color: var(--detail2);
  border-radius: 2px;
  transition: width 0.1s linear;
}